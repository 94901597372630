import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MemberstackProvider } from "@memberstack/react";  // Import MemberstackProvider

const memberstackConfig = {
  publicKey: process.env.REACT_APP_MEMBERSTACK_PUBLIC_KEY,
  domain: 'https://memberstack-client.spxgamma.com', 
  useCookies: true,
  setCookieOnRootDomain: true,
  secure: true,
  sameSite: "None",
};

//Disable console logs in production environment
if (process.env.NODE_ENV === 'production') {
    console.log = function() {};  // Disable console.log
    console.error = function() {};  // Optionally disable console.error
    console.warn = function() {};  // Optionally disable console.warn
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MemberstackProvider config={{ publicKey: process.env.REACT_APP_MEMBERSTACK_PUBLIC_KEY, ...memberstackConfig }}>
    <App />
  </MemberstackProvider>
);

reportWebVitals();





