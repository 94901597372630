import React, { useEffect, useRef } from 'react';
import useWebSocketDataStore from "../Store/WebSocketDataStore.jsx"
import { createChart, CrosshairMode, PriceScaleMode, LineStyle} from 'lightweight-charts';
import SeriesToggleButton from "./SeriesToggleButton.jsx";
import './Style.css'; // Import your CSS file

// Define series configurations for call-leading and put-leading trends
const TRENDSERIES_CALL_LEADING = {
    key: 'callLeadingSeries',
    type: 'area',
    scale: 0,
    // buttonLabel: 'GTI (Call Leading)',
    chartOptions: {
        // title: 'GTS - Call Leading',
        topColor: 'rgba(2,204,253,0.6)',
        bottomColor: 'rgba(245,236,7,0.8)',
        lineColor: 'transparent', //
        lineWidth: 1,
        priceScaleId: 'right',
        priceLineVisible: false,
        lastValueVisible: false,
    },
};

const TRENDSERIES_PUT_LEADING = {
    key: 'putLeadingSeries',
    type: 'area',
    scale: 0,
    // buttonLabel: 'GTI (Put Leading)',
    chartOptions: {
        // title: 'GTS - Put Leading',
        bottomColor: 'rgba(245,236,7,0.8)',
        topColor: 'rgb(255,0,0, 0.6)',
        lineColor: 'transparent',
        lineWidth: 1,
        priceScaleId: 'right',
        priceLineVisible: false,
        lastValueVisible: false,
    },
};

const HORIZONTAL_LINES = [
    { price: 0.2, color: 'white', lineWidth: 5  } ,// Adjusted threshold line for "No Trend"
    { price: .5, color: 'rgba(0, 0, 0, 0)' },
];

//Create the series for the Support and Resistance lines as well as the Daily Dynamic Highs and Low
const createSRSeries = (callData, putData, closeValue) => {
  const createSeriesConfig = (key, price, color, title) => ({
    key,
    type: 'line',
    scale: 2,
    chartOptions: {
      color,
      lineWidth: 1,
      lineStyle: LineStyle.Dashed,
      axisLabelVisible: false,
      title,
    },
    price  // Only include the strike price
  });

  // Directly create series for the top 2 calls and puts
  const callSeries = callData.slice(0, 2).map((item, index) =>
    createSeriesConfig(`Call${index + 1}`, item.strike, 'green', `R ${index + 1}`)
  );
  const putSeries = putData.slice(0, 2).map((item, index) =>
    createSeriesConfig(`Put${index + 1}`, item.strike, 'red', `S ${index + 1}`)
  );

    // Initial filtering for the Daily Dynamic Levels. Filter for rows 6-10 for calls and puts
  const filteredCallData = callData.slice(5, 10); // Rows 6-10 have index 5-9
  const filteredPutData = putData.slice(5, 10);

      // Initialize variables for highest volume call and put series
  let highestVolumeCallSeries = null, highestVolumePutSeries = null;

      // Find the highest volume call with a strike price greater than closeValue
  if (filteredCallData.length > 0) {
    const highestVolumeCall = filteredCallData
      .filter(item => item.strike > closeValue)
      .reduce((prev, current) => (prev.volume > current.volume) ? prev : current, { volume: -Infinity });

    if (highestVolumeCall.volume !== -Infinity) {
      highestVolumeCallSeries = createSeriesConfig('HighestVolumeCall', highestVolumeCall.strike, '#B18800', 'Dynamic Day High');
    }
  }

      // Find the highest volume put with a strike price less than closeValue
  if (filteredPutData.length > 0) {
    const highestVolumePut = filteredPutData
      .filter(item => item.strike < closeValue)
      .reduce((prev, current) => (prev.volume > current.volume) ? prev : current, { volume: -Infinity });

    if (highestVolumePut.volume !== -Infinity) {
      highestVolumePutSeries = createSeriesConfig('HighestVolumePut', highestVolumePut.strike, '#B18800', 'Dynamic Day Low');
    }
  }

      // Compile all series, including or excluding highest volume series based on availability
  return [
    ...callSeries,
    ...putSeries,
    ...(highestVolumeCallSeries ? [highestVolumeCallSeries] : []),
    ...(highestVolumePutSeries ? [highestVolumePutSeries] : [])
  ];
};


//Series for Option flow and Candles
const SERIES = [
    // Candlestick series
    {
      key: 'candlestick',
      type: 'candlestick',
      scale: 0,
      buttonLabel: 'Candles',
      chartOptions: {
        priceScaleId: 'right',
        color: '#838ca1',
        upColor: 'transparent', // Hollow candlesticks for upward movement
        downColor: 'transparent', // Hollow candlesticks for downward movement
        borderUpColor: '#B18800', // Yellow border for downward movement
        borderDownColor: '#838ca1', // Green border for upward movement
        wickDownColor: '#838ca1', // Wick color for downward movement
        wickUpColor: '#838ca1', // Wick color for upward movement
        priceLineVisible: false,
        // If available, set border width here
      }
    },
    // Call Delta
    {
      key: 'total_call_volume_delta',
      type: 'line',
      scale: 2,
      buttonLabel: 'Call Delta',
      chartOptions: {
        title: 'C Δ',
        color: '#ff00e6',
        lineWidth: 1,
        priceScaleId: 'left',
        priceLineVisible: false,

      }
    },
      // Put Delta
    {
      key: 'total_put_volume_delta',
      type: 'line',
      scale: 2,
      buttonLabel: 'Put Delta',
      chartOptions: {
        title: 'P Δ',
        color: '#7b70fa',
        lineWidth: 1,
        priceScaleId: 'left',
        priceLineVisible: false,
      }
    },
   //Call GEX
    {
      key: 'total_call_volume_gamma',
      type: 'line',
      scale:  0,
      buttonLabel: 'Call GEX',
      chartOptions: {
        title: 'C Γ',
        lineWidth: 1,
        priceScaleId: 'left',
        color: '#00ff00',
        priceLineVisible: false,
      }
    },

   //  Put GEX
    {
      key: 'total_put_volume_gamma',
      type: 'line',
      scale: 0,
      buttonLabel: 'Put GEX',
      chartOptions: {
        title: 'P Γ',
        color: 'rgb(241,0,0)',
        lineWidth: 1,
        priceScaleId: 'left',
        priceLineVisible: false,

      }
    },

    // Total GEX
    {
      key: 'total_gex',
      type: 'area',
      scale: 3,
      buttonLabel: 'Total GEX',
      chartOptions: {
        title: 'T Γ',
        topColor: 'rgb(255,255,255)',
        lineColor: 'rgb(255,255,255)',
        bottomColor: 'rgba(103,103,103,0)',
        lineWidth: 1,
        priceScaleId: 'left',
        priceLineVisible: false,

      }
    },
   // Call Theta
     {
       key: 'total_call_volume_theta',
       type: 'line',
       scale: 2,
       buttonLabel: 'Call Charm',
       chartOptions: {
         title: 'C θ',
         lineWidth: 1,
         priceScaleId: 'left',
         color: '#00ffff',
         priceLineVisible: false,
       }
     },
     // Put Theta
     {
       key: 'total_put_volume_theta',
       type: 'line',
       scale: 2,
       buttonLabel: 'Put Charm',
       chartOptions: {
         title: 'P θ',
         lineWidth: 1,
         priceScaleId: 'left',
         color: '#ff6a00',
         priceLineVisible: false,
       }
     },

    // Call Vega
     {
       key: 'total_call_volume_vega',
       type: 'line',
       scale: 2,
       buttonLabel: 'Call Vanna',
       chartOptions: {
         title: 'C ν',
         color: '#0055ff',
         lineWidth: 1,
         priceScaleId: 'left',
         priceLineVisible: false,
       }
     },
     // Put Vega
     {
       key: 'total_put_volume_vega',
       type: 'line',
       scale: 2,
       buttonLabel: 'Put Vanna',
       chartOptions: {
         title: 'P ν',
         color: '#ffaa00',
         lineWidth: 1,
         priceScaleId: 'left',
         priceLineVisible: false,
       }
     },
]




const ChartComponent = ({onChartCreated}) => {
  const { data, toggles, callData, putData, correlations } = useWebSocketDataStore(state => ({
    data: state.data,
    toggles: state.toggles,
    callData: state.callData,
    putData: state.putData,
    correlations: state.correlations,
  }));

  const chartContainerRef = useRef(null);
  const trendChartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const trendChartRef = useRef(null);
  const seriesRef = useRef({});
  const trendSeriesRef = useRef({});



//Function to transform data for each series and remove duplicates.
// This step pairs each variable with the appropriate time value/format to be plotted on the chart.
  const transformDataForSeries = (data, seriesKey) => {
    let lastNonNullValue = null;
    return data.map(item => {
      let value = item[seriesKey];

      if (value === null) {
        value = lastNonNullValue; // Use last non-null value if current is null
      } else {
        lastNonNullValue = value; // Update last non-null value
      }
      return {
        time: item.time,
        value: value // This might be the original value or the last non-null value
      };
    });
  };

  //This step pairs the OHLC data with the time values to create the candlesticks.
  const transformDataForCandlestick = (data) => {
    const transformedData = data.map(item => ({
      time: item.time, // Assuming 'time' is your timestamp field
      open: item.open,
      high: item.high,
      low: item.low,
      close: item.close,
    }));
    return transformedData;
  };


  useEffect(() => {
    if (!data || data.length === 0 || !chartContainerRef.current)  {
      return;
    }

    if (!chartRef.current) {

      chartRef.current = createChart(chartContainerRef.current, {
        //Widths are adjusted to allow the tables to be integrated along with toggles for each indicator.
        width: window.innerWidth * .95,
        height: window.innerHeight * .80,
        alignLabels: true,
        timeScale: {
         rightOffset:12,
         barSpacing: 0,
         shiftVisibleRangeOnNewBar: true,
         fixLeftEdge: true,
         fixRightEdge: false,
         rightBarStaysOnScroll: true,
         leftBarStaysOnScroll: true,
         borderVisible: false,
         borderColor: "#000000",
         visible: false,  // Keep visible true if you want to control tick formatting
         timeVisible: true,  // Ensure that time labels are not shown
         secondsVisible: false,  // Ensure that seconds are not shown
         tickMarkFormatter: () => ''
        },
        leftPriceScale: {
         autoScale: true,
         borderVisible: false,
         visible: true,
         mode: PriceScaleMode.Normal, // Enable manual scaling
       },
        rightPriceScale: {
         autoScale: true,
         borderVisible: false,
         mode: PriceScaleMode.Normal,
      },
       grid: {
        vertLines: {
          color: 'rgba(42, 46, 57, 0)',
        },
        horzLines: {
          color: 'rgba(87,87,87,0.23)',
        },
      },
      layout: {
        background: {
          color: '#000000'
        },
        textColor: '#d1d4dc',
      },
      priceScale: {
        autoScale: true,
      },
      crosshair: {
        mode: CrosshairMode.Normal,
                // Vertical crosshair line (showing Date in Label)
        vertLine: {
            width: 8,
            color: '#C3BCDB44',
            style: LineStyle.Solid,
            labelBackgroundColor: '#B18800',
        },

        // Horizontal crosshair line (showing Price in Label)
        horzLine: {
            color: '#C3BCDB44',
            labelBackgroundColor: '#B18800',
        },
    },

     });
      //Timescale set to 390 for the number of individual bars in a day.
      // Fit content only uses available dat and is best for daily data.
      //chartRef.current.timeScale().fitContent();
    chartRef.current.timeScale().setVisibleLogicalRange({ from: 0, to: 410 });

      // Initialize series based on SERIES configuration. Uses the series configuration structure from teh start of
      // the code and applies those styling components to the chart.
      // Specifically, this is for the individual greek indicators, the totalgex plot, and the candlesticks.
    SERIES.forEach(seriesInfo => {
      let series;
      if (seriesInfo.type === 'line') {
        series = chartRef.current.addLineSeries(seriesInfo.chartOptions);
        const lineSeriesData = transformDataForSeries(data, seriesInfo.key);
        series.setData(lineSeriesData);
        series.applyOptions({ visible: toggles[seriesInfo.key] });
      } else if (seriesInfo.type === 'area') {
        series = chartRef.current.addAreaSeries(seriesInfo.chartOptions);
        const areaSeriesData = transformDataForSeries(data, seriesInfo.key);
        series.setData(areaSeriesData);
        series.applyOptions({ visible: toggles[seriesInfo.key] });
      } else if (seriesInfo.type === 'candlestick') {
        series = chartRef.current.addCandlestickSeries(seriesInfo.chartOptions);
        const candlestickData = transformDataForCandlestick(data);
        series.setData(candlestickData);
      }
      // Initialize the series with visibility based on toggles
      if (series) {
        series.applyOptions({
          visible: toggles[seriesInfo.key], // Set visibility based on toggle value
        });
      }
      // Add the zero line to the series. This is 0 level for total gex
      if (series) {
        series.createPriceLine({
          price: 0,
          color: 'white',
          lineWidth: 1,
          lineStyle: LineStyle.Solid, // Using LineStyle from the imported module
          axisLabelVisible: false,
          title: 'Zero Line',
        });
      }
      seriesRef.current[seriesInfo.key] = series;
    });


    //Handle how the chart resizes when the user adjusts the screen size. The 1080 breakpoint adjust for desktop and mobile users.
      const handleResize = () => {
          let newWidth, newHeight;

          // Check the window width and set the dimensions accordingly
          if (window.innerWidth <= 1080) {
              newWidth = window.innerWidth * 0.95;
              newHeight = window.innerHeight * 0.65;
          } else {
              newWidth = window.innerWidth * 0.95;
              newHeight = window.innerHeight * 0.65;
          }

          // Resize the chart with the new dimensions
          chartRef.current.resize(newWidth, newHeight);

          // Adjust the visible range or scroll position if needed. Maintains the 390 bars for a single trading day.
          const timeScale = chartRef.current.timeScale();
          timeScale.setVisibleLogicalRange({ from: 0, to: 410 });
      };

      // Add the resize event listener
      window.addEventListener('resize', handleResize);

      // Call handleResize initially to set the size based on the initial window size
      handleResize();

      return () => {
      };
    }
  }, [data, toggles]);



  useEffect(() => {
    // Check if there is data available, if not, return early and do nothing
    if (!data || data.length === 0) {
      return;
    }

    // Function to update the chart with new data
    const updateChartData = () => {
      // Iterate through each series defined in SERIES
      SERIES.forEach(seriesInfo => {
        // Get the corresponding series from the seriesRef
        const series = seriesRef.current[seriesInfo.key];
        if (series) {
          // Get the latest data point from the data array
          const latestDataPoint = data[data.length - 1]; // Get the latest data point
          if (seriesInfo.type === 'candlestick') {
            // If the series type is candlestick, transform the latest data point for candlestick
            const latestCandlestickData = transformDataForCandlestick([latestDataPoint])[0];
            // Update the candlestick series with the transformed data
            series.update(latestCandlestickData);
          } else {
            // For other series types, transform the latest data point for the specific series
            const latestSeriesData = transformDataForSeries([latestDataPoint], seriesInfo.key)[0];
            // Update the series with the transformed data
            series.update(latestSeriesData);
          }
        }
      });
    }

    // This sets up the chart to be scrolled to the last data point. Very important to keep this.
    if (chartRef.current) {
      const timeScale = chartRef.current.timeScale();

      // For example, to keep the chart scrolled to the latest data point:
      timeScale.scrollToPosition(-1, false);
    }

    updateChartData();
  }, [data]); // Ensure 'data' and 'SERIES' are included

  useEffect(() => {
    if (!chartRef.current) return;

    // Assuming the last entry in the data array contains the latest close value
    const latestDataPoint = data.length > 0 ? data[data.length - 1] : null;
    const closeValue = latestDataPoint ? latestDataPoint.close : null;

    // Ensure you have a valid close value before proceeding
    if (closeValue === null) {
      console.warn("Close value is not available.");
      return;
    }

    // Generate series configurations for call and put data with the close value
    const srSeriesConfigurations = createSRSeries(callData, putData, closeValue);

    srSeriesConfigurations.forEach(seriesConfig => {
      let series = seriesRef.current[seriesConfig.key];
      if (!series) {
        series = chartRef.current.addLineSeries(seriesConfig.chartOptions);
        seriesRef.current[seriesConfig.key] = series;
      }

      // Ensure the price is a number
      const numericPrice = Number(seriesConfig.price);

      // Update the series data with a dummy time value and numeric price
      series.setData([{ time: 1, value: numericPrice }]);
    });

  }, [data, callData, putData]); // Ensure 'data', 'callData', and 'putData' are included

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    // Adjust the visibility of series based on toggles
    Object.keys(seriesRef.current).forEach(seriesKey => {
      const series = seriesRef.current[seriesKey];
      if (series) {
        series.applyOptions({
          visible: toggles[seriesKey], // Set visibility based on toggle value
        });
      }
    });
  }, [toggles]); // Ensure 'toggles' is included


    useEffect(() => {
        if (!correlations || Object.keys(correlations).length === 0 || !trendChartContainerRef.current) {
            return;
        }

        if (!trendChartRef.current) {
            trendChartRef.current = createChart(trendChartContainerRef.current, {
                width: window.innerWidth * 0.95,
                height: window.innerHeight * 0.50,
                alignLabels: true,
                timeScale: {
                    rightOffset: 12,
                    barSpacing: 0,
                    shiftVisibleRangeOnNewBar: false,
                    fixLeftEdge: true,
                    fixRightEdge: false,
                    rightBarStaysOnScroll: true,
                    leftBarStaysOnScroll: true,
                    borderVisible: false,
                    borderColor: "#000000",
                    timeVisible: true,
                    secondsVisible: false,
                },
                leftPriceScale: {
                    borderVisible: false,
                    visible: true,
                    mode: PriceScaleMode.Normal,
                    scaleMargins: { top: 0.1, bottom: 0.1 },
                },
                rightPriceScale: {
                    borderVisible: false,
                    mode: PriceScaleMode.Normal,
                    scaleMargins: { top: 0.1, bottom: 0.1 },
                },
                grid: {
                    vertLines: { color: 'rgba(42, 46, 57, 0)' },
                    horzLines: { color: '#000000' },
                },
                layout: {
                    background: { color: '#000000' },
                    textColor: '#d1d4dc',
                },
                crosshair: {
                    mode: CrosshairMode.Normal,
                    vertLine: {
                        width: 8,
                        color: '#C3BCDB44',
                        style: LineStyle.Solid,
                        labelBackgroundColor: '#B18800',
                    },
                    horzLine: {
                        color: '#C3BCDB44',
                        labelBackgroundColor: '#B18800',
                    },
                },
            });

            // Create the continuous top line series
            const topLineSeries = trendChartRef.current.addLineSeries({
                color: 'rgb(255, 255, 255)', // Consistent color for top line
                lineWidth: 1,
                priceScaleId: 'right',
                priceLineVisible: false, // Disable the line at the last value
                lastValueVisible: true, // Disable visibility of the last value
            });

            // Explicitly add topLineSeries to trendSeriesRef for crosshair use
            trendSeriesRef.current['topLine'] = topLineSeries;

            let currentSeries = null;
            let currentTrend = correlations.correlations20[0]?.trend;
            let segmentData = [];
            const topLineData = []; // Data for continuous top line

            const setSeriesData = (series, data) => {
                series.setData(data.sort((a, b) => a.time - b.time));
            };

            correlations.correlations20.forEach((dataPoint, index) => {
                const { trend, time, value } = dataPoint;
                const adjustedTime = time !== 0 ? time : correlations.correlations20[0].time + index * 60;

                // Continuously add points to the top line series
                topLineData.push({ time: adjustedTime, value });

                if (trend !== currentTrend) {
                    if (currentSeries && segmentData.length > 0) {
                        segmentData.push({
                            time: adjustedTime,
                            value: segmentData[segmentData.length - 1].value, // Connecting point
                        });
                        setSeriesData(currentSeries, segmentData);
                    }

                    currentSeries = trend === 'call'
                        ? trendChartRef.current.addAreaSeries(TRENDSERIES_CALL_LEADING.chartOptions)
                        : trendChartRef.current.addAreaSeries(TRENDSERIES_PUT_LEADING.chartOptions);

                    segmentData = [{ time: adjustedTime, value }];
                    currentTrend = trend;
                } else {
                    segmentData.push({ time: adjustedTime, value });
                }
            });

            // Finalize the last series segment
            if (currentSeries && segmentData.length > 0) {
                setSeriesData(currentSeries, segmentData);
            }

            // Set data for the continuous top line series to ensure no gaps
            topLineSeries.setData(topLineData);

            // Add horizontal lines to the trend chart
            HORIZONTAL_LINES.forEach(line => {
                const lineSeries = trendChartRef.current.addLineSeries({
                    color: line.color,
                    priceLineVisible: true,
                    lineWidth: 3,
                    lineStyle: LineStyle.Solid
                });
                lineSeries.setData([{ time: 0, value: line.price }]);
            });

            // Handle resizing of the trend chart
            const handleResize = () => {
                const newWidth = window.innerWidth * 0.95;
                const newHeight = window.innerHeight <= 1080 ? window.innerHeight * 0.20 : window.innerHeight * 0.20;
                trendChartRef.current.resize(newWidth, newHeight);
                const timeScale = trendChartRef.current.timeScale();
                timeScale.setVisibleLogicalRange({ from: 0, to: 410 });
            };

            window.addEventListener('resize', handleResize);
            handleResize();

            return () => {
                window.removeEventListener('resize', handleResize);
                trendChartRef.current.remove();
                trendChartRef.current = null;
            };
        } else {
            // For live updates
            const latestDataPoint = correlations.correlations20[correlations.correlations20.length - 1];
            if (latestDataPoint) {
                const { trend, time, value } = latestDataPoint;
                const updatedTime = time !== 0 ? time : Date.now() / 1000;

                // Update the continuous top line series
                trendSeriesRef.current['topLine'].update({ time: updatedTime, value });

                // Update specific trend series
                const currentSeries = trend === 'call'
                    ? trendSeriesRef.current[TRENDSERIES_CALL_LEADING.key]
                    : trendSeriesRef.current[TRENDSERIES_PUT_LEADING.key];

                if (currentSeries) {
                    currentSeries.update({ time: updatedTime, value });
                }
            }
        }
    }, [correlations]);


    // This useEffect and subsequent functions handle the crosshair sync across both plots.
    useEffect(() => {
        if (chartRef.current && trendChartRef.current && seriesRef.current['candlestick'] && trendSeriesRef.current['topLine']) {
            const mainChart = chartRef.current;
            const trendChart = trendChartRef.current;
            const mainSeries = seriesRef.current['candlestick'];
            const trendSeries = trendSeriesRef.current['topLine']; // Use topLine for crosshair sync

            // Function to safely apply changes only if charts are not disposed
            const safelyApply = (fn) => {
                if (mainChart && trendChart) {
                    fn();
                }
            };

            // Sync the visible time range between charts
            const syncTimeRange = (timeRange) => {
                safelyApply(() => {
                    trendChart.timeScale().setVisibleLogicalRange(timeRange);
                });
            };

            const reverseSyncTimeRange = (timeRange) => {
                safelyApply(() => {
                    mainChart.timeScale().setVisibleLogicalRange(timeRange);
                });
            };

            mainChart.timeScale().subscribeVisibleLogicalRangeChange(syncTimeRange);
            trendChart.timeScale().subscribeVisibleLogicalRangeChange(reverseSyncTimeRange);

            // Sync the crosshair between charts
            const handleMainChartCrosshairMove = (param) => {
                safelyApply(() => {
                    const dataPoint = getCrosshairDataPoint(mainSeries, param);
                    if (dataPoint) {
                        syncCrosshair(trendChart, trendSeries, dataPoint);
                    } else {
                        trendChart.clearCrosshairPosition();
                    }
                });
            };

            const handleTrendChartCrosshairMove = (param) => {
                safelyApply(() => {
                    const dataPoint = getCrosshairDataPoint(trendSeries, param);
                    if (dataPoint) {
                        syncCrosshair(mainChart, mainSeries, dataPoint);
                    } else {
                        mainChart.clearCrosshairPosition();
                    }
                });
            };

            mainChart.subscribeCrosshairMove(handleMainChartCrosshairMove);
            trendChart.subscribeCrosshairMove(handleTrendChartCrosshairMove);

            return () => {
                safelyApply(() => {
                    mainChart.timeScale().unsubscribeVisibleLogicalRangeChange(syncTimeRange);
                    trendChart.timeScale().unsubscribeVisibleLogicalRangeChange(reverseSyncTimeRange);
                    mainChart.unsubscribeCrosshairMove(handleMainChartCrosshairMove);
                    trendChart.unsubscribeCrosshairMove(handleTrendChartCrosshairMove);
                });
            };
        }
    }, [chartRef.current, trendChartRef.current, trendSeriesRef.current['topLine']]);

    // Utility function to extract the crosshair data point
    function getCrosshairDataPoint(series, param) {
        if (!param || !param.time || !param.seriesData || typeof param.seriesData.get !== 'function') {
            return null;
        }

        const dataPoint = param.seriesData.get(series);
        if (dataPoint) {
            if (dataPoint.close !== undefined) {
                return { time: param.time, value: dataPoint.close };
            } else if (dataPoint.value !== undefined) {
                return { time: param.time, value: dataPoint.value };
            }
        }
        return null;
    }

    // Function to synchronize the crosshair position
    function syncCrosshair(chart, series, dataPoint) {
        if (dataPoint && dataPoint.time !== undefined && dataPoint.value !== undefined) {
            try {
                chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
            } catch (error) {
                chart.clearCrosshairPosition();
            }
        } else {
            chart.clearCrosshairPosition();
        }
    }


  const renderLabelWithColor = (seriesKey, labelText) => {
    const series = SERIES.find(s => s.key === seriesKey);
    let color;
    if (series) {
      color = series.key === 'total_gex' ? series.chartOptions.topColor : series.chartOptions.color;
    } else {
      color = 'transparent'; // Fallback to 'transparent' if series not found
    }
    return (
      <span>
        <span style={{
          backgroundColor: color,
          width: '10px',
          height: '10px',
          display: 'inline-block',
          marginRight: '5px'
        }} />
        {series.buttonLabel}
      </span>
    );
  };

    return (
        <div className="chart-component-container">
            {/*<div className="message-section" style={{ color: '#B18800', fontSize: '12pt' }}>*/}
            {/*    In the coming weeks, SPX Gamma will be transitioning to a model that will require users to log in before accessing our dashboards. We will offer a 30-day free trial to all users during this transition period. It has been a pleasure to provide this service free of charge over the past 18 months. As we continue to grow, it’s necessary to begin covering the costs associated with developing and maintaining the platform, including data feeds, cloud hosting, and more. We truly appreciate your continued support and look forward to providing even more value as we move forward together.*/}
            {/*</div>*/}

            <div className="toggle-buttons-container">
                {SERIES.map((series) => (
                    <SeriesToggleButton
                        key={series.key}
                        seriesKey={series.key}
                        label={renderLabelWithColor(series.key, series.chartOptions.title)}
                        className="indicator"
                    />
                ))}
            </div>

            <div className="content-container">
                <div className="chart-container" style={{ position: 'relative' }}>
                    <div ref={chartContainerRef} /> {/* Main chart container */}
                    <div className="overlay">0 DTE Dashboard</div> {/* Overlay for Main Chart */}
                </div>

                <div className="trend-chart-container" style={{ position: 'relative' }}>
                    <div ref={trendChartContainerRef} /> {/* Trend chart container */}
                    <div className="overlay">GEX Trend Strength</div> {/* Overlay for Trend Chart */}
                </div>
            </div>
        </div>
    );

};

export default ChartComponent;

