import React, { useState, useEffect } from 'react'; import ReactGA from 'react-ga4';
import WebSocketClient from './Components/WebSocketClient';
import ChartComponent from './Components/ChartComponent';
import useWebSocketDataStore from "./Store/WebSocketDataStore.jsx";
import TablesComponent from "./Components/TablesComponent";
import './App.css';
import CorrelationComponent from "./Components/TrendComponent.jsx";
import { useMemberstack } from "@memberstack/react";  // Import the useMemberstack hook

const allowedPlanIds = [
  'prc_legacy-membership-bg54063e',  
  'prc_free-trial-legacy-membership-vi20zyi',  
  'pln_vip-membership-c0ea0lhm',
  'pln_72-hour-free-trial-k8d606hc'
];

function App() {
  const { data, callData, putData } = useWebSocketDataStore();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1080);
  const [loadingMember, setLoadingMember] = useState(true);  // Track member loading state
  const [member, setMember] = useState(null);  // Store member information
  const memberstack = useMemberstack();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1080);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

// Fetch the current member and check their plan
  useEffect(() => {
    memberstack.getCurrentMember()
      .then(({ data: member }) => {
        if (!member) {
          // If no member is found, redirect to the denied page
          window.location.href = 'https://www.spxgamma.com/denied';
        } else {
          // Check if the member's plan is allowed
          const planConnections = member.planConnections;
          const hasValidPlan = planConnections?.some(plan => {
            // Use plan.planId for free plans, plan.payment.priceId for paid plans
            return allowedPlanIds.includes(plan.payment?.priceId || plan.planId);
          });

          if (hasValidPlan) {
            setMember(member);  // Set the member info
            setLoadingMember(false);  // Stop loading
            console.log("Current member with valid plan:", member);
          } else {
            // Redirect if the member doesn't have a valid plan
            window.location.href = 'https://www.spxgamma.com/denied';
          }
        }
      })
      .catch(err => {
        console.error("Error fetching current member:", err);
        window.location.href = 'https://www.spxgamma.com/denied';  // Redirect on error
      });
  }, [memberstack]);

  // While member info is loading, show a loading message
  if (loadingMember) {
    return <div>Loading...</div>;  // Display a loading screen while checking membership
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <WebSocketClient />
      <div style={{ width: '100%', padding: '0px' }}>
        <div style={{ marginBottom: '0px' }}>
          <ChartComponent data={data} />
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0px',
        }}>
          <CorrelationComponent />
          <div style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            gap: '20px',
          }}>
            <div style={{ flex: 1 }}>
              <TablesComponent callData={callData} putData={putData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;











//Working code before memberstack.
// import React, { useState, useEffect } from 'react';
// import ReactGA from 'react-ga4';
// import WebSocketClient from './Components/WebSocketClient';
// import ChartComponent from './Components/ChartComponent';
// import useWebSocketDataStore from "./Store/WebSocketDataStore.jsx";
// import TablesComponent from "./Components/TablesComponent";
// import './App.css';
// import CorrelationComponent from "./Components/TrendComponent.jsx";
//
// function App() {
//   const { data, callData, putData } = useWebSocketDataStore();
//   const [isMobile, setIsMobile] = useState(window.innerWidth < 1080);
//
//   useEffect(() => {
//     // Initialize Google Analytics
//     ReactGA.initialize('G-3T651JWGNW');
//     const handleResize = () => {
//       setIsMobile(window.innerWidth < 1080);
//     };
//
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);
//
//   return (
//     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
//       <WebSocketClient />
//       <div style={{ width: '100%',  padding: '0px' }}>
//         <div style={{ marginBottom: '0px' }}>
//           <ChartComponent data={data} />
//         </div>
//         <div style={{
//           display: 'flex',
//           flexDirection: 'column',
//           gap: '0px',
//         }}>
//           <CorrelationComponent />
//           <div style={{
//             display: 'flex',
//             flexDirection: isMobile ? 'column' : 'row',
//             justifyContent: 'space-between',
//             gap: '20px',
//           }}>
//             <div style={{ flex: 1 }}>
//               <TablesComponent callData={callData} putData={putData} />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
//
// export default App;

