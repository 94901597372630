// import React, { useEffect } from 'react';
// import useWebSocketDataStore from '../Store/WebSocketDataStore.jsx';

// const CorrelationComponent = () => {
//     const data = useWebSocketDataStore(state => state.data);
//     const setCorrelations = useWebSocketDataStore(state => state.setCorrelations);

//     const applySmoothingFilter = (data, alpha = 0.2) => {
//         const a1 = Math.exp(-1.414 * Math.PI * alpha);
//         const b1 = 2 * a1 * Math.cos(1.414 * Math.PI * alpha);
//         const c2 = b1;
//         const c3 = -a1 * a1;
//         const c1 = 1 - c2 - c3;

//         const smoothedData = [];
//         smoothedData[0] = 0; // Start the first value at 0
//         smoothedData[1] = data[1] || 0; // Initialize the second value with fallback

//         for (let i = 2; i < data.length; i++) {
//             smoothedData[i] = c1 * data[i] + c2 * smoothedData[i - 1] + c3 * smoothedData[i - 2];
//         }

//         return smoothedData;
//     };

//     useEffect(() => {
//         if (data && data.length > 0) {
//             const trendStrengths = [];
//             const callVolumeGamma = data.map(row => ({ time: row.time, value: row.total_call_volume_gamma ?? 0 }));
//             const putVolumeGamma = data.map(row => ({ time: row.time, value: row.total_put_volume_gamma ?? 0 }));

//             // Set initial value to 0 for the first data point and neutral trend
//             trendStrengths.push({ time: callVolumeGamma[0]?.time || 0, value: 0, trend: 'neutral' });

//             // Initialize previous values for fallback
//             let previousCallGEX = callVolumeGamma[0]?.value || 0;
//             let previousPutGEX = putVolumeGamma[0]?.value || 0;

//             // Calculate leading GEX trend and its strength for subsequent points
//             for (let i = 1; i < callVolumeGamma.length; i++) {
//                 const callGEX = callVolumeGamma[i].value || previousCallGEX; // Use previous value if current is 0
//                 const putGEX = putVolumeGamma[i].value || previousPutGEX; // Use previous value if current is 0

//                 const denominator = callGEX + putGEX;
//                 if (denominator !== 0) {
//                     const strength = (callGEX - putGEX) / denominator; // Normalized strength
//                     trendStrengths.push({ time: callVolumeGamma[i].time, value: strength, trend: callGEX > putGEX ? 'call' : 'put' });
//                 } else {
//                     trendStrengths.push({ time: callVolumeGamma[i].time, value: trendStrengths[i - 1]?.value || 0, trend: 'neutral' });
//                 }

//                 // Update previous values
//                 previousCallGEX = callGEX;
//                 previousPutGEX = putGEX;
//             }

//             // Apply smoothing to the trend strengths
//             const smoothedStrengths = applySmoothingFilter(trendStrengths.map(d => d.value));

//             // Combine smoothed strengths back with the trend and time data
//             const smoothedTrendData = trendStrengths.map((d, index) => ({
//                 time: d.time,
//                 value: smoothedStrengths[index],
//                 trend: d.trend // Retain the 'call' or 'put' trend type
//             }));

//             // Set the resulting data
//             setCorrelations({ correlations20: smoothedTrendData });
//         } else {
//             console.log('No data available for calculations');
//         }
//     }, [data, setCorrelations]);

//     return null;
// };

// export default CorrelationComponent;


//Working code but can not handle 0 Values. 
import React, { useEffect } from 'react';
import useWebSocketDataStore from '../Store/WebSocketDataStore.jsx';

const CorrelationComponent = () => {
    const data = useWebSocketDataStore(state => state.data);
    const setCorrelations = useWebSocketDataStore(state => state.setCorrelations);

    const applySmoothingFilter = (data, alpha = 0.2) => {
        const a1 = Math.exp(-1.414 * Math.PI * alpha);
        const b1 = 2 * a1 * Math.cos(1.414 * Math.PI * alpha);
        const c2 = b1;
        const c3 = -a1 * a1;
        const c1 = 1 - c2 - c3;

        const smoothedData = [];
        smoothedData[0] = 0; // Start the first value at 0
        smoothedData[1] = data[1]; // Initialize the second value normally

        for (let i = 2; i < data.length; i++) {
            smoothedData[i] = c1 * data[i] + c2 * smoothedData[i - 1] + c3 * smoothedData[i - 2];
        }

        return smoothedData;
    };

    useEffect(() => {
        if (data && data.length > 0) {
            const trendStrengths = [];
            const callVolumeGamma = data.map(row => ({ time: row.time, value: row.total_call_volume_gamma }));
            const putVolumeGamma = data.map(row => ({ time: row.time, value: row.total_put_volume_gamma }));

            // Set initial value to 0 for the first data point and neutral trend
            trendStrengths.push({ time: callVolumeGamma[0]?.time || 0, value: 0, trend: 'neutral' });

            // Calculate leading GEX trend and its strength for subsequent points
            for (let i = 1; i < callVolumeGamma.length; i++) {
                const callGEX = callVolumeGamma[i].value;
                const putGEX = putVolumeGamma[i].value;

                if (callGEX > putGEX) {
                    // Call GEX is leading
                    const strength = (callGEX - putGEX) / (callGEX + putGEX); // Normalized strength
                    trendStrengths.push({ time: callVolumeGamma[i].time, value: strength, trend: 'call' });
                } else {
                    // Put GEX is leading
                    const strength = (putGEX - callGEX) / (callGEX + putGEX); // Normalized strength
                    trendStrengths.push({ time: putVolumeGamma[i].time, value: strength, trend: 'put' });
                }
            }

            // Apply smoothing to the trend strengths
            const smoothedStrengths = applySmoothingFilter(trendStrengths.map(d => d.value));

            // Combine smoothed strengths back with the trend and time data
            const smoothedTrendData = trendStrengths.map((d, index) => ({
                time: d.time,
                value: smoothedStrengths[index],
                trend: d.trend // Retain the 'call' or 'put' trend type
            }));

            // Set the resulting data
            setCorrelations({ correlations20: smoothedTrendData });
        } else {
            console.log('No data available for calculations');
        }
    }, [data, setCorrelations]);

    return null;
};

export default CorrelationComponent;
